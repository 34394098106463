import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
import { ref } from 'vue';
import { historyBill } from '@/http';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'TransactionHistory',
  setup(__props) {
    const {
      t
    } = useI18n();
    const page = ref(1);
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const actions = {
      recharge: t('recharge'),
      withdraw: t('withdraw'),
      pay_contract: t('buy_second_contract'),
      settlement_contract: t('settle_second_contract'),
      pay_lever: t('buy_lever'),
      settlement_lever: t('settle_lever'),
      stake_lp: t('stake_lp'),
      earnings_lp: t('earnings_lp'),
      settlement_lp: t('settlement_lp'),
      admin: t('admin')
    };
    const onLoad = () => {
      loading.value = true;
      historyBill({
        page: page.value,
        type: 'other',
        perPage: 10
      }).then(({
        data
      }) => {
        loading.value = false;
        if (data.current_page === 1) {
          list.value = data.data;
        } else {
          list.value = list.value.concat(data.data);
        }
        finished.value = data.current_page >= data.last_page;
        if (!finished.value) {
          page.value++;
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createBlock(_component_van_list, {
        loading: loading.value,
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value = $event),
        finished: finished.value,
        "finished-text": _ctx.$t('no_more'),
        onLoad: onLoad
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, item => {
          return _openBlock(), _createElementBlock("div", {
            key: item.id,
            class: "transaction-item"
          }, [_createElementVNode("span", {
            class: _normalizeClass([Number(item.amount) > 0 ? 'green-text' : 'red-text'])
          }, _toDisplayString(Number(item.amount) > 0 ? '+' + item.amount : item.amount), 3), _createElementVNode("div", null, [item.type === 'admin' ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(item.reason), 1)) : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(actions[item.type]), 1)), _createElementVNode("p", null, _toDisplayString(item.created_at), 1)])]);
        }), 128))]),
        _: 1
      }, 8, ["loading", "finished", "finished-text"]);
    };
  }
};